import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Nav() {
  const [user, setUser] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleUserActivity = () => {
    const tokenExpiration = localStorage.getItem('token_expiration');
    if (tokenExpiration && new Date().getTime() > tokenExpiration) {
      localStorage.removeItem('admin_token');
      localStorage.removeItem('token_expiration');
      navigate('/login');
    }
  };
  
  useEffect(() => {
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
  
    return () => {
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [navigate]);


  useEffect(() => {
    const storedToken = localStorage.getItem('admin_token');
    if (!storedToken) {
      console.log("token not set");
      navigate('/login');
    } else {
      console.log(storedToken);
    }
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem('admin_token');
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Application-Type': 'application/json',
      };

      try {
        const response = await axios.get('https://api.nkolozi.com/api/Admin/admin', { headers });
        setUser(response.data.data);
        console.log(response.data.data.name);
        if (response.data.data.status !== 'active' || response.data.data.user_type !== 'admin') {
          await handleLogout();
        }
      } catch (error) {
        setError(error.message || 'An error occurred while fetching admin details.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleLogout = async () => {
    const token = localStorage.getItem('admin_token');
    try {
      await fetch('https://api.nkolozi.com/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      localStorage.removeItem('admin_token');
      document.cookie = "admin_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      navigate('/login');
    } catch (error) {
      console.error('An error occurred during logout', error);
    }
  };

  const blockUser = async (id) => {
    const token = localStorage.getItem('admin_token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.get(`https://api.nkolozi.com/api/Admin/block-user/${id}`, { headers });
      console.log('User blocked:', response.data);
    } catch (error) {
      console.error('Error blocking user:', error);
    }
  };

  const unblockUser = async (id) => {
    const token = localStorage.getItem('admin_token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.get(`https://api.nkolozi.com/api/Admin/un-block-user/${id}`, { headers });
      console.log('User unblocked:', response.data);
    } catch (error) {
      console.error('Error unblocking user:', error);
    }
  };

  const activateUser = async (id) => {
    const token = localStorage.getItem('admin_token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.get(`https://api.nkolozi.com/api/Admin/activate-client/${id}`, { headers });
      console.log('User activated:', response.data);
    } catch (error) {
      console.error('Error activating user:', error);
    }
  };

  return (
    <div>
      <nav className="sb-topnav navbar navbar-expand navbar-dark fixed-top" style={{ backgroundColor: '#b03a30' }}>
        <a className="navbar-brand ps-3" href="index.html">
        Nkolozi Money Transfer:  {user.name} {user.surname}
        </a>
        <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
          <div className="input-group"></div>
        </form>
        <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-user fa-fw" />
            </a>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdown"
            >
              <li>
                <a className="dropdown-item" href="#!">
                  Settings
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#!">
                  Activity Log
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item" onClick={handleLogout}>
                  Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <Outlet />
    </div>
  );
}

export default Nav;
