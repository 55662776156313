import React, { useState } from 'react';
import axios from 'axios';

const RbzComp = () => {
  const [transactionId, setTransactionId] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('admin_token');
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const response = await axios.post(
        'https://api.nkolozi.com/api/Admin/rbz-report',
        { transaction_id: transactionId },
        { headers }
      );
      console.log(response.data.message);
      setResponseMessage(response.data.message || 'Request was successful');
    } catch (error) {
      setResponseMessage(
        error.response?.data?.message || 'An error occurred while processing your request'
      );
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center responsive" style={{ marginTop: '100px' }}>
      <div className="card" style={{ width: '40rem' }}>
        <div className="card-body">
          <h5 className="card-title text-center">RBZ Form</h5>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="transactionId" className="form-label">
                Transaction ID
              </label>
              <input
                type="text"
                className="form-control"
                id="transactionId"
                placeholder="Enter Transaction ID"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Submit
            </button>
          </form>
          {responseMessage && (
            <div className="alert alert-info mt-3" role="alert">
              {responseMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RbzComp;
